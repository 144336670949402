export const GOOGLE_MAPS_URL =
    "https://www.google.com/maps/dir//705+Sisk+Ave+%23111,+Oxford,+MS+38655/@34.3733943,-89.5784263,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88807ba64197676f:0xbf8d7632db112e29!2m2!1d-89.4960251!2d34.3734219?entry=ttu&g_ep=EgoyMDI0MTAxNC4wIKXMDSoASAFQAw%3D%3D"

export const menuOptions = {
    APPETIZERS: "Appetizers",
    PIZZA: "Pizza",
    WINGS: "Wings",
    TENDERS: "Tenders",
    SANDWICHES: "Sandwiches",
    SIDES: "Sides",
    SALADS: "Salads",
    DRINKS: "Drinks",
    KIDS_MEALS: "Kids Meals",
}

export const menuImageUrls = {
    LOADED_POTATO_SKINS: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/01.+Loaded+Potato+Skins+(2)-min.jpg",
    JALAPENO_BABY_WRAP: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/02.+Jalapeno+Baby+Wrap-min.jpg",
    GARLIC_ROMANO_RANCH: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/03.+Garlic+Romano+Ranch+(1)-min.jpg",
    FRIED_OKRA: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/04.+Fried+Okra-min.jpg",
    BACON_AND_CHEDDAR: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/05.+Bacon+%26+Cheddar+(1)-min.jpg",
    BUFFALO: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/06.+Buffalo+(1)-min.jpg",
    BBQ_CHICKEN_NACHOS: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/07.+BBQ+Chicken+Nachos-min.jpg",
    HONEY_GOLD: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/08.+Honey+Gold+(2)-min.jpg",
    WESTERN_RANCH_FRIES: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/09.+Western+Ranch+Fries-min.jpg",
    QUESADILLA: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/10.+Chicken+Bacon+Ranch+Quesadilla-min.jpg",
    SMOKY_AND_THE_BANDIT: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/11.+Smokey+%26+The+Bandit-min.jpg",
    CHICKEN_TENDERS: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/12.+Chicken+Tenders-min.jpg",
    LEMON_PEPPER: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/13.+Lemon+Pepper+(1)-min.jpg",
    BIG_TEXAS: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/14.+Big+Texas-min.jpg",
    BBQ_BACON_CHEESE_BABY: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/15.+BBQ+Bacon+Cheddar+Baby-min.jpg",
    CHICKEN_SALAD: "https://southern-coop-wings.s3.us-east-2.amazonaws.com/food/16.+Chicken+Salad-min.jpg",
}

export const menuItems = {
    APPETIZERS: [
        {
            name: "Cheese Sticks",
            price: 6.99,
            desc: "Served with a dipping sauce",
        },
        {
            name: "Pickle Spears",
            price: 7.99,
            desc: "Served with a dipping sauce",
        },
        {
            name: "Fried Mushrooms",
            price: 7.49,
            desc: "Served with a dipping sauce",
        },
        {
            name: "Onion Rings",
            price: 7.99,
            desc: "Served with a dipping sauce",
        },
        {
            name: "Loaded Potato Skins",
            price: 7.99,
            desc: "Served with sour cream upon request",
            image: menuImageUrls.LOADED_POTATO_SKINS,
        },
        {
            name: "Texas Cheese Fries",
            price: 7.99,
            desc: "Our famous fries topped with your choice of cheese dip and bacon",
        },
        {
            name: "Western Ranch Fries",
            price: 10.49,
            desc: "Ranch, BBQ, cheese, bacon and jalapenos",
            image: menuImageUrls.WESTERN_RANCH_FRIES,
        },
        {
            name: "Nacho Cheese Dip",
            price: 6.49,
            desc: "Nacho cheese and our house-made chips",
        },
        {
            name: (
                <>
                    Chicken Nachos
                    <br />
                    BBQ Chicken Nachos
                </>
            ),
            price: (
                <>
                    $10.49
                    <br />
                    $10.99
                </>
            ),
            desc: "Our house-made chips topped with chicken, your choice of cheese dip, jalapeno, and sour cream",
            image: menuImageUrls.BBQ_CHICKEN_NACHOS,
        },
        // {
        //     name: "Chips & Salsa",
        //     price: 2.99,
        //     desc: "Our delicious house-made chips with salsa",
        // },
        // {
        //     name: "Queso Bites",
        //     price: 6.99,
        //     desc: "Comes with a dipping sauce",
        // },
        {
            name: "Chicken Bacon Ranch Quesadilla",
            price: 11.49,
            desc: "Served with jalapeno and sour cream upon request",
            image: menuImageUrls.QUESADILLA,
        },
        {
            name: "Buffalo Chicken Quesadilla",
            price: 11.49,
            desc: "Chicken, house buffalo, house ranch and mozzarella. Served with jalapeno and sour cream upon request",
        },
    ],
    PIZZA: [
        {
            name: "Tender Melt",
            price: 10.99,
            desc: "Choose your base sauce topped with Chicken and Mozzarella",
        },
        {
            name: "Ranch Hand",
            price: 11.99,
            desc: "Ranch base with chicken, bacon and mozzarella",
        },
        {
            name: "Buffalo Bird",
            price: 11.99,
            desc: "Our mouthwatering homemade Buffalo base, ranch, chicken, and mozzarella",
        },
        {
            name: "Golden Bird",
            price: 11.99,
            desc: "A delicious Honey Gold base, ranch, chicken and mozzarella",
        },
        {
            name: "Big Texas",
            price: 11.99,
            desc: "BBQ base, chicken, honey, Cajun spices and mozzarella",
        },
        {
            name: "Mr. Italy",
            price: 11.99,
            desc: "Marinara base, chicken, garlic butter, romano and mozzarella",
        },
        {
            name: "The Italian",
            price: 10.49,
            desc: "Topped with marinara, pepperoni and mozzarella",
        },
        {
            name: "King Alfredo",
            price: 11.99,
            desc: "Alfredo base, chicken and mozzarella",
        },
    ],
    SANDWICHES: [
        { name: "Tender Melt", price: 11.49, desc: "Mozzarella and choice of sauce" },
        { name: "Just A Chick", price: 11.49, desc: "Lettuce, tomato and cheese" },
        { name: "Chicken BLT", price: 12.25, desc: "Bacon, lettuce, tomato and mayo" },
        { name: "The Ranch Hand", price: 12.25, desc: "Ranch, mozzarella and bacon" },
        { name: "Buffalo Bird", price: 12.25, desc: "Buffalo sauce, ranch and mozzarella" },
        { name: "Golden Bird", price: 12.25, desc: "Honey Gold sauce, ranch and mozzarella" },
        { name: "The Bacon Cheddar Baby", price: 12.25, desc: "Bacon and cheddar cheese" },
        { name: "The BBQ Bacon Cheddar Baby", price: 12.25, desc: "Bacon, cheddar and BBQ" },
        { name: "Tex Mex", price: 12.25, desc: "Jalapenos, BBQ and cheddar cheese" },
        { name: "Big Texas", price: 12.25, desc: "BBQ, honey, cajun spices and mozzarella" },
        { name: "King Alfredo", price: 12.25, desc: "Alfredo sauce and mozzarella" },
        { name: "Mr. Italy", price: 12.25, desc: "Topped with garlic butter, romano seasoning and mozzarella" },
        { name: "Little Italy", price: 12.25, desc: "Topped with marinara and mozzarella" },
        { name: "The Asian", price: 11.49, desc: "Tiger sauce, mozzarella and cheddar" },
        { name: "Chicken Wrap", price: 12.25, desc: "Cheese, bacon, lettuce, tomato and mayo" },
        { name: "Jalapeno Baby Wrap", price: 12.49, desc: "Jalapenos, bacon, lettuce, tomato, cheese, mayo and ranch" },
    ],
}
