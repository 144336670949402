import React, { useState, useEffect } from "react"
import ReactGA from "react-ga"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons"

import AppHeader from "./AppHeader"
import AppSubHeader from "./AppSubHeader"
import AppFooter from "./AppFooter"
import MenuItem from "./MenuItem"
import WingSauces from "./shared/wing-sauces"
import MenuFile from "../menu/southern-coop-menu-2024.jpg"
import { menuOptions, menuItems, menuImageUrls } from "../config/constants"
import "../style/menu.css"

const Menu = () => {
    const [option, setOption] = useState(menuOptions.APPETIZERS)

    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview(window.location.pathname)
    }, [])

    const dippingSauces = (
        <div className="addon">
            <div className="addon-name">Dipping Sauces</div>
            <div className="addon-option">Coop sauce</div>
            <div className="addon-option">Scratch-made ranch</div>
            <div className="addon-option">Blue cheese</div>
            <div className="addon-option">Honey mustard</div>
            <div className="addon-option">Marinara</div>
            <div className="addon-option">BBQ</div>
        </div>
    )

    return (
        <div>
            <AppHeader delay={false} />
            <AppSubHeader text="Our Menu" buttonText="View Full Menu" buttonIcon={faDownload} buttonUrl={MenuFile} />
            <div className="menu-alert">Prices subject to change</div>
            <div className="menu-options">
                <div className="menu-options-scroll-indicator-left">
                    <FontAwesomeIcon icon={faCaretLeft} />
                </div>
                <div className="menu-options-scrollable">
                    {Object.keys(menuOptions).map((key) => {
                        return (
                            <div
                                key={key}
                                className={`menu-option button ${option === menuOptions[key] ? "selected" : ""}`}
                                onClick={() => setOption(menuOptions[key])}
                            >
                                {menuOptions[key]}
                            </div>
                        )
                    })}
                </div>
                <div className="menu-options-scroll-indicator-right">
                    <FontAwesomeIcon icon={faCaretRight} />
                </div>
            </div>
            <div className="menu">
                <div className="menu-selected-option">{option}</div>
                {option === menuOptions.APPETIZERS && (
                    <React.Fragment>
                        {dippingSauces}
                        {menuItems.APPETIZERS.map((item, index) => {
                            return <MenuItem key={`APPETIZERS-${index}`} name={item.name} price={item.price} desc={item.desc} imageSrc={item.image} />
                        })}
                        {/* <MenuItem name="Cheese Sticks" price={6.99} desc="Served with a dipping sauce" />
                        <MenuItem name="Pickle Spears" price={7.99} desc="Served with a dipping sauce" />
                        <MenuItem name="Fried Mushrooms" price={7.49} desc="Served with a dipping sauce" />
                        <MenuItem name="Onion Rings" price={7.99} desc="Served with a dipping sauce" />
                        <MenuItem
                            name="Loaded Potato Skins"
                            price={7.99}
                            desc="Served with sour cream upon request"
                            imageSrc={menuImageUrls["Loaded Potato Skins"]}
                        />
                        <MenuItem name="Texas Cheese Fries" price={7.99} desc="Our famous fries topped with your choice of cheese dip and bacon" />
                        <MenuItem
                            name="Western Ranch Fries"
                            price={10.49}
                            desc="Ranch, BBQ, cheese, bacon and jalapenos"
                            imageSrc={menuImageUrls["Western Ranch Fries"]}
                        />
                        <MenuItem name="Chips & Salsa" price={2.99} desc="Our delicious house-made chips with salsa" />
                        <MenuItem name="Nacho Cheese Dip" price={6.49} desc="Nacho cheese and our house-made chips" />
                        <MenuItem name="Queso Bites" price={6.99} desc="Comes with a dipping sauce" />
                        <MenuItem
                            name={
                                <>
                                    Chicken Nachos
                                    <br />
                                    BBQ Chicken Nachos
                                </>
                            }
                            price={
                                <>
                                    $10.49
                                    <br />
                                    $10.99
                                </>
                            }
                            desc="Our house-made chips topped with chicken, your choice of cheese dip, jalapeno, and sour cream"
                            imageSrc={menuImageUrls["BBQ Chicken Nachos"]}
                        />
                        <MenuItem
                            name="Chicken Bacon Ranch Quesadilla"
                            price={11.49}
                            desc="Served with jalapeno and sour cream upon request"
                            imageSrc={menuImageUrls["Quesadilla"]}
                        />
                        <MenuItem name="Buffalo Chicken Quesadilla" price={11.49} desc="" /> */}
                        {/* <MenuItem name="Jalapeno Poppers" price={5.49} /> */}
                    </React.Fragment>
                )}
                {option === menuOptions.PIZZA && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">
                            Your choice of our 10" crust or flat bread baked in wood fired stone pizza oven.
                            <br />
                            Base Options: Ranch, Buffalo, Honey Gold, BBQ, Marinara, Alfredo
                        </div>
                        {menuItems.PIZZA.map((item, index) => {
                            return <MenuItem key={`PIZZA-${index}`} name={item.name} price={item.price} desc={item.desc} imageSrc={item.image} />
                        })}
                    </React.Fragment>
                )}
                {option === menuOptions.WINGS && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">All meals served with your choice of side and a dipping sauce.</div>
                        <WingSauces />
                        <div className="wing-type">
                            <div className="wing-type-name">BONE-IN WINGS</div>
                            <div className="wing-meal-type">Meal</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">8 PIECES</div>
                                    <div className="price">$12.75</div>
                                </div>
                                <div>
                                    <div className="size">12 PIECES</div>
                                    <div className="price">$16.75</div>
                                </div>
                                <div>
                                    <div className="size">16 PIECES</div>
                                    <div className="price">$20.75</div>
                                </div>
                                <div>
                                    <div className="size">24 PIECES</div>
                                    <div className="price">$28.99</div>
                                </div>
                            </div>
                            <div className="wing-meal-type">Solo</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">30 PIECES</div>
                                    <div className="price">$36.99</div>
                                </div>
                                <div>
                                    <div className="size">36 PIECES</div>
                                    <div className="price">$43.99</div>
                                </div>
                                <div>
                                    <div className="size">48 PIECES</div>
                                    <div className="price">$55.99</div>
                                </div>
                                <div>
                                    <div className="size">60 PIECES</div>
                                    <div className="price">$69.99</div>
                                </div>
                            </div>
                        </div>

                        <div className="wing-type">
                            <div className="wing-type-name">BONELESS WINGS</div>
                            <div className="wing-meal-type">Meal</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">8 PIECES</div>
                                    <div className="price">$10.75</div>
                                </div>
                                <div>
                                    <div className="size">12 PIECES</div>
                                    <div className="price">$13.25</div>
                                </div>
                                <div>
                                    <div className="size">16 PIECES</div>
                                    <div className="price">$16.75</div>
                                </div>
                                <div>
                                    <div className="size">24 PIECES</div>
                                    <div className="price">$20.75</div>
                                </div>
                            </div>
                            <div className="wing-meal-type">Solo</div>
                            <div className="wing-prices-wrapper">
                                <div>
                                    <div className="size">30 PIECES</div>
                                    <div className="price">$29.49</div>
                                </div>
                                <div>
                                    <div className="size">36 PIECES</div>
                                    <div className="price">$34.99</div>
                                </div>
                                <div>
                                    <div className="size">48 PIECES</div>
                                    <div className="price">$39.99</div>
                                </div>
                                <div>
                                    <div className="size">60 PIECES</div>
                                    <div className="price">$48.99</div>
                                </div>
                            </div>
                        </div>
                        <div className="wing-images">
                            <div className="wing-images-row-1">
                                <img src={menuImageUrls.GARLIC_ROMANO_RANCH} className="wing-1" alt="wing" />
                                <img src={menuImageUrls.BACON_AND_CHEDDAR} className="wing-2" alt="wing" />
                                <img src={menuImageUrls.BUFFALO} className="wing-3" alt="wing" />
                            </div>
                            <div className="wing-images-row-2">
                                <img src={menuImageUrls.HONEY_GOLD} className="wing-4" alt="wing" />
                                <img src={menuImageUrls.LEMON_PEPPER} className="wing-5" alt="wing" />
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {option === menuOptions.TENDERS && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">
                            Served fried or grilled with a side and a dipping sauce
                            <br />
                            Dip tenders in your favorite wing sauce for only $1.49!
                        </div>
                        {dippingSauces}
                        <MenuItem name="3 Pieces" price={10.49} />
                        <MenuItem name="4 Pieces" price={11.99} />
                        {/* <MenuItem name="12 Pieces" price={17.99} /> */}
                        <div className="tenders-image">
                            <img src={menuImageUrls.CHICKEN_TENDERS} alt="chicken-tenders" />
                        </div>
                    </React.Fragment>
                )}
                {option === menuOptions.SANDWICHES && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">
                            Sandwiches are made with our delicious homemade fried or grilled tenders on an 8" hoagie dressed as noted. Served with a
                            side.
                        </div>
                        {menuItems.SANDWICHES.map((item, index) => {
                            return <MenuItem key={`SANDWICHES-${index}`} name={item.name} price={item.price} desc={item.desc} imageSrc={item.image} />
                        })}
                        {/* <MenuItem name="Tender Melt" price={11.49} desc="Mozzarella and choice of sauce" />
                        <MenuItem name="The Western Chick" price={9.49} desc="Salsa, ranch and cheddar cheese" />
                        <MenuItem name="The Ranch Hand" price={10.25} desc="Ranch, mozzarella and bacon" />
                        <MenuItem name="The Bacon Cheddar Baby" price={10.25} desc="Bacon and cheddar cheese" />
                        <MenuItem
                            name="The BBQ Bacon Cheddar Baby"
                            price={10.25}
                            desc="Bacon, cheddar and BBQ"
                            imageSrc={menuImageUrls["BBQ Bacon Cheddar Baby"]}
                        />
                        <MenuItem name="Buffalo Bird" price={9.49} desc="Buffalo sauce, ranch and mozzarella" />
                        <MenuItem name="Mr. Pepper" price={9.0} desc="Nacho cheese dip, salsa, lettuce and tomato" />
                        <MenuItem name="Tex Mex" price={9.49} desc="Jalapenos, BBQ and cheddar cheese" />
                        <MenuItem name="King Alfredo" price={9.49} desc="Afredo sauce and mozzarella" />
                        <MenuItem
                            name="Mr. Italy"
                            price={9.49}
                            desc="Dipped in garlic butter, garlic pepper, romano, mozzarella - with a side of marinara"
                        />
                        <MenuItem name="Little Italy" price={9.49} desc="Topped with marinara and mozzarella" />
                        <MenuItem
                            name="Big Texas"
                            price={9.49}
                            desc="BBQ, honey, cajun spices and mozzarella"
                            imageSrc={menuImageUrls["Big Texas"]}
                        />
                        <MenuItem name="The Asian" price={9.49} desc="Tiger sauce, mozzarella and cheddar" />
                        <MenuItem name="Chicken BLT" price={10.25} desc="Bacon, lettuce, tomato and mayo" />
                        <MenuItem
                            name="Chicken Wrap"
                            price={10.25}
                            desc="Grilled or fried chicken, cheese, bacon, lettuce, tomato and a choice of sauce"
                        />
                        <MenuItem name="Just a Chick" price={9.49} desc="Lettuce, tomato, cheese and honey mustard" />
                        <MenuItem
                            name="Smokey and The Bandit"
                            price={9.49}
                            desc="Smoked sausage, cheese, lettuce and tomato"
                            imageSrc={menuImageUrls["Smokey & The Bandit"]}
                        /> */}
                    </React.Fragment>
                )}
                {option === menuOptions.SALADS && (
                    <React.Fragment>
                        <div className="addon">
                            <div className="addon-name">Dressings</div>
                            <div className="addon-option">Scratch-made Ranch</div>
                            <div className="addon-option">Lite Ranch</div>
                            <div className="addon-option">Thousand Island</div>
                            <div className="addon-option">Blue Cheese</div>
                            <div className="addon-option">Honey Mustard</div>
                            <div className="addon-option">Italian</div>
                            <div className="addon-option">Caesar</div>
                        </div>
                        <MenuItem
                            name="House Salad"
                            price={
                                <div>
                                    (SM) $6.49
                                    <br />
                                    (LG) $8.99
                                </div>
                            }
                            desc="Mixed greens, topped with cheddar & monterey jack cheese, bacon, cucumbers, cherry tomatoes and eggs"
                        />
                        <MenuItem
                            name="Chicken Salad"
                            price={11.99}
                            desc="Our house salad topped with your choice of fried or grilled chicken"
                            imageSrc={menuImageUrls["Chicken Salad"]}
                        />
                    </React.Fragment>
                )}
                {option === menuOptions.SIDES && (
                    <React.Fragment>
                        <MenuItem name="Fried Okra" price={3.75} />
                        <MenuItem name="Loaded Baked Potato Salad" price={2.75} />
                        <MenuItem name="Carrots" price={2.99} />
                        <MenuItem name="Onion Rings" desc="Meal upcharge" price={"+ $1.99"} />
                        <MenuItem name="Salad" desc="Meal upcharge" price={"+ $1.99"} />
                        <MenuItem
                            name="French Fries"
                            price={
                                <div>
                                    (SM) $3.49
                                    <br />
                                    (LG) $4.99
                                </div>
                            }
                        />
                        <MenuItem name="Texas Cheese Fries" desc="Take your fries to the next level!" price={"+ $2.75"} />
                    </React.Fragment>
                )}
                {option === menuOptions.DRINKS && (
                    <MenuItem
                        name="Sodas & Teas"
                        price={2.49}
                        desc="Coke, Diet Coke, Sprite, Dr. Pepper, Lemonade, Fruit Punch, Sweet Tea, Unsweet Tea"
                    />
                )}
                {option === menuOptions.KIDS_MEALS && (
                    <React.Fragment>
                        <div className="menu-selected-option-description">Served with fries and a drink</div>
                        <MenuItem name="Corn Dog" price={6.99} />
                        <MenuItem name="Chicken Tender" price={6.99} />
                        <MenuItem name="Chicken Sandwich" price={6.99} />
                        <MenuItem name="Chelsy's Grilled Cheese" price={6.99} />
                    </React.Fragment>
                )}
            </div>
            <AppFooter />
        </div>
    )
}

export default Menu
