import React from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FaStar, FaCar } from "react-icons/fa"
import { faFacebookF, faTwitter, faInstagram, faGoogle, faYelp } from "@fortawesome/free-brands-svg-icons"

import { GOOGLE_MAPS_URL } from "../config/constants"
import Signature from "./shared/signature"
import "../style/app-footer.css"

const AppFooter = () => {
    const year = new Date().getFullYear()

    return (
        <>
            <div className="socials">
                <div className="socials-box">
                    <div className="feedback">
                        <div className="title">LEAVE FEEDBACK</div>
                        <div className="ratings">
                            <div className="google">
                                4.8 <FaStar className="star-icon" /> on Google
                            </div>
                            <div className="yelp">
                                4.5 <FaStar className="star-icon" /> on Yelp
                            </div>
                        </div>
                        <div className="feedback-actions">
                            <a href="https://goo.gl/maps/GHDUtrWQJPukuN22A" target="_blank" rel="noopener noreferrer">
                                <div className="feedback-button button">Review on Google</div>
                            </a>
                            <a href="https://www.yelp.com/writeareview/biz/ZsxfJcnmsxCOCMpBgy9zhw" target="_blank" rel="noopener noreferrer">
                                <div className="feedback-button button">Review on Yelp</div>
                            </a>
                        </div>
                    </div>
                    <div className="get-social">
                        <div className="title">GET SOCIAL</div>
                        <div className="social-icons">
                            <a href="https://www.facebook.com/southerncoopwings/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} className="social-icon"></FontAwesomeIcon>
                            </a>
                            <a href="https://twitter.com/CoopSouthern" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} className="social-icon"></FontAwesomeIcon>
                            </a>
                            <a href="https://www.instagram.com/southerncoopwings/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} className="social-icon"></FontAwesomeIcon>
                            </a>
                            <a href="https://goo.gl/maps/F6X7cwG9VQYM7QM29" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGoogle} className="social-icon"></FontAwesomeIcon>
                            </a>
                            <a href="https://www.yelp.com/biz/southern-coop-oxford" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faYelp} className="social-icon"></FontAwesomeIcon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <div className="links">
                    <div className="links-column">
                        <div className="title">HOURS</div>
                        <div>Monday - Saturday</div>
                        <div>OPEN for lunch & dinner</div>
                        <br />
                        <div>Sunday</div>
                        <div>CLOSED</div>
                        {/* <div>(662) 638-3767</div> */}
                    </div>
                    <div className="links-column">
                        <div className="title">ADDRESS</div>
                        <div>705 Sisk Ave Suite 111</div>
                        <div>Oxford, Mississippi 38655</div>
                        <div>
                            <a href={GOOGLE_MAPS_URL} target="_blank" rel="noopener noreferrer">
                                <FaCar style={{ marginRight: "4px", fontSize: "1.2em", marginBottom: "-2px" }} />
                                Get directions
                            </a>
                        </div>
                    </div>
                    <div className="links-column">
                        <div className="title">QUICK LINKS</div>
                        <div>
                            <a href="https://order.southerncoopwings.com/s/order" target="_blank" rel="noopener noreferrer">
                                Order
                            </a>
                        </div>
                        <div>
                            <Link to="/menu">Menu</Link>
                        </div>
                        <div>
                            <Link to="/catering">Catering</Link>
                        </div>
                        <div>
                            <Link to="/hirings">Hirings</Link>
                        </div>
                        <div>
                            <Link to="/contact">Contact</Link>
                        </div>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="signature">
                    <div>{`© ${year} Southern Coop Wings`}</div>
                    <Signature />
                </div>
            </div>
        </>
    )
}

export default AppFooter
