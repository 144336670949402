import React, { useEffect } from "react"
import ReactGA from "react-ga"
import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons"

import AppHeader from "./AppHeader"
import AppSubHeader from "./AppSubHeader"
import AppFooter from "./AppFooter"
import ContactImage from "../images/about-1.jpg"
import "../style/contact.css"

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
        ReactGA.initialize("UA-147225916-1")
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <div>
            <AppHeader delay={false} />
            <AppSubHeader internal text="Our Contact" buttonText="Back to Home" buttonIcon={faLongArrowAltLeft} buttonUrl={"/"} />
            <div className="contact">
                <div className="contact-info">
                    <div className="contact-info-box">
                        <div className="contact-info-box-title">Phone number</div>
                        <div className="contact-info-box-content">(662) 638-3767</div>
                        <div className="contact-info-box-title">Address</div>
                        <div className="contact-info-box-content">
                            705 Sisk Ave #111
                            <br />
                            Oxford, MS 38655
                        </div>
                        <div className="contact-info-box-content">
                            Follow us on all social media for our latest restaurant updates, specials & hours!
                        </div>
                    </div>
                </div>
                <div className="contact-image">
                    <img src={ContactImage} alt="contact" />
                </div>
            </div>
            <AppFooter />
        </div>
    )
}

export default Contact
