import React from "react"

import "../style/banner.css"

const Banner = () => {
    return (
        <div className="banner">
            <p className="title">
                <span role="img" aria-label="chicken">
                    🐓
                </span>{" "}
                Online order now!
            </p>
            <div>
                Southern Coop online ordering is now available. Simply place your order with our secured ordering site and show your ID to pick up in
                store!
            </div>
            <br />
            <a href="https://order.southerncoopwings.com/s/order" target="_blank" rel="noopener noreferrer">
                <div className="button">Order online</div>
            </a>
        </div>
    )
}

export default Banner
