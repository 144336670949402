import React, { useState, useEffect, useMemo } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import Home from "./components/Home"
import Menu from "./components/Menu"
import Catering from "./components/Catering"
import Contact from "./components/Contact"
import Hirings from "./components/Hirings"
import NotFound from "./components/404"
import { ResponsiveContext } from "./components/context/ResponsiveContext"
import { theme } from "./config/theme"

const App = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)

    useEffect(() => {
        const updateResponsiveValue = () => {
            setIsMobile(window.innerWidth < 768)
            setWindowHeight(window.innerHeight)
        }

        window.addEventListener("resize", updateResponsiveValue)

        return () => {
            window.removeEventListener("resize", updateResponsiveValue)
        }
    }, [])

    const responsiveValue = useMemo(
        () => ({
            isMobile,
            windowHeight,
        }),
        [isMobile, windowHeight]
    )

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <ResponsiveContext.Provider value={responsiveValue}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/menu" element={<Menu />} />
                        <Route path="/catering" element={<Catering />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/hirings" element={<Hirings />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </BrowserRouter>
            </ResponsiveContext.Provider>
        </ChakraProvider>
    )
}

export default App
